.App {
  font-family: 'Poppins', sans-serif;
  display: flex;
  flex-direction: column;
  height: 100vh;
  background: black;
  color: white;
}

@media (width <= 800px) {
  #crewPick {
    display: none;
  }

  #contactContainer {
    width: 100vw;
    margin-top: 200px;
  }
}
