

.home img {
    background-position: center center;
    background-size: cover;
    background-attachment: fixed;
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    min-width: 100%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    text-align: center;
    transform: translate3d(0,0,0);
    z-index: 0;
}

.siteTitle {
    position: absolute;
    top: calc(50% - 40px);
    text-align: center;
    width: 100%;
}

.bgOverlay {
    background: linear-gradient(180deg, rgba(0,0,0,0.40242034313725494) 0%, rgba(0,0,0,0.19793855042016806) 13%, rgba(0,0,0,0.19793855042016806) 100%);
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute;
    z-index: 0;
}

.navbar {
    font-weight: 600;
    font-size: 12px;
    letter-spacing: .97px;
    line-height: 1em;
    text-transform: uppercase;
    color: white; 
    z-index: 1;
    margin-bottom: 25px;
}

.navbar a {
    text-decoration: none;
    color: white;
    transition: color 0.2s;
}

.navbar a:hover {
    color: rgb(190, 190, 190);
}

ul {
    display: flex;
    padding: 0;
    align-items: center;
    justify-content: space-between;
    list-style: none;
    width: 220px;
    margin: 25px auto 0;
}

ul li {
    padding: 0;
    margin: 0;
}

.siteTitle {
    color: white;
    text-transform: uppercase;
    user-select: none;
    z-index: 6;
}

.personName {
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: 2px;
    word-spacing: 2px;
}

.personPosition {
    font-size: 12px;
    letter-spacing: 1px;
}

.socialFooter {
    margin-top: auto;
    margin-bottom: 24px;
    z-index: 1;
}

.socialFooter ul {
    width: 110px;
    opacity: 0.6;
    transition: opacity 0.2s;
}

.socialFooter ul:hover {
    opacity: 1;
}

.socialFooter ul li {
    background: white;
    height: 25px;
    width: 28px;
    border-radius: 15px;
    padding-top: 2px;
    text-align: center;
}

svg {
    color: #222;
    font-size: 12px;
}

.works {
    width: 100vw;
    margin: 0 auto;
    text-transform: uppercase;
}

.oneWork {
    cursor: pointer;
    text-decoration: none;
    color: white;
    display: block;
    position: relative;
}

.workOverlay {
    background: rgb(0, 0, 0);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    transition: opacity 0.2s;
    z-index: 1;
}

.oneWork:hover .workOverlay {
    opacity: 0.2;
}

.workTitle {
    font-size: 20px;
    letter-spacing: 1px;
    word-spacing: 4px;
    font-weight: 800;
    position: absolute;
    bottom: calc(50% - 10px);
    width: 100%;
    text-align: center;
    z-index: 1;
}

.previewHolder {
    width: 100vw;
    height: calc(100vw / 3.39);
    overflow: hidden;
    width: 100vw;
}

.works img {
    object-fit: cover;
    width: inherit;
    height: 100%;
}

@keyframes fadeInAnimation {
    from { opacity: 0; }
    to { opacity: 1; }
}

.lazyImage {
    animation: fadeInAnimation 0.2s linear forwards;
}

.contact {
    display: flex;
}

.contactContainer {
    text-align: center;
    width: 50vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 14px;
}

.contactTitle {
    font-weight: 800;
}

.contact img {
    width: 50vw;
}
